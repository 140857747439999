module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Movementkingdom","short_name":"Move","start_url":"/","background_color":"#f7f0eb","theme_color":"#FFF3C7","display":"standalone","icon":"src/images/icon-bg-white.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e5cfe418af2760818930af93108a89bf"},
    },{
      plugin: require('../plugins/gatsby-plugin-telegram/gatsby-browser.js'),
      options: {"plugins":[],"channelName":"mkdrgbnews"},
    },{
      plugin: require('../plugins/gatsby-plugin-google-calendar/gatsby-browser.js'),
      options: {"plugins":[],"calendarIds":["hla1m0ms5tppffg9mv1q22oojg@group.calendar.google.com"],"timeMin":"2023-03-10T02:50:56.895Z","maxResults":50,"singleEvents":true,"orderBy":"startTime"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
